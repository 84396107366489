// let MODE = process.env.REACT_APP_MODE || 'DEVELOPMENT'
// console.log('MODE')
console.log(process.env.REACT_APP_MODE)
// Ropsten Details
// <MoralisProvider appId="tExjBBJ1s8aHSk1idnOJJOrjJON007ziPVLk88fn" serverUrl="https://xltiotldo06b.usemoralis.com:2053/server">
// Rinkyby Details
//<MoralisProvider appId="SijSgTYh4BzkYWphDDTxtpaASUW2n8ALsss5GM2S" serverUrl="https://2wrtzriiflxd.usemoralis.com:2053/server">
// Eth Mainnet Details
// <MoralisProvider appId="eBgX8zrn8xVXiY0baycBC2ICW9MCah153bbZflyr" serverUrl="https://o5frzwdhepdu.usemoralis.com:2053/server">

const MORALIS_SERVER = {
    //mainnet_AppID: `tNwRRjAA9TwIg40TAu8c3PYCFELFQnKiGJtWvVIp`,
    //mainnet_serverURL: `https://fysfo7qo74zu.usemoralis.com:2053/server`,
    mainnet_AppID: `Gxt5aS8FpZHnFZHX53dxIBvjxdOuIltLN7x5NlH7`,
    mainnet_serverURL: `https://cntdhts5tzqw.usemoralis.com:2053/server`,
    ropsten_AppID: `tExjBBJ1s8aHSk1idnOJJOrjJON007ziPVLk88fn`,
    ropsten_serverURL: `https://xltiotldo06b.usemoralis.com:2053/server`,
    rinkyby_AppID: `pvXzRq7VZz43EKQ5nUCZzrdOx6JBqR46TB8MG3EP`,
    rinkyby_serverURL: `https://udvyzcwutain.usemoralis.com:2053/server`,
    rinkyby_AppID_old: `SijSgTYh4BzkYWphDDTxtpaASUW2n8ALsss5GM2S`,
    rinkyby_serverURL_old: `https://2wrtzriiflxd.usemoralis.com:2053/server`,
}

let SC_1_JSON_URI = ""
let SC_1_JSON_URI_MORALIS = ""
let SC_1_IMAGE_URI = ""
let SC_3_JSON_URI = ""
let SC_3_JSON_URI_MORALIS = ""
let SC_3_IMAGE_URI = ""
let SC_4_JSON_URI = ""
let SC_4_IMAGE_URI = ""

let ETHERSCAN_BASE_URI = ""


// NETWORK SWITCHER
// NETWORK SWITCHER
// NETWORK SWITCHER

let network = "rinkeby"
//let network = "mainnet"

// NETWORK SWITCHER
// NETWORK SWITCHER
// NETWORK SWITCHER
// NETWORK SWITCHER
let moralis_app_ID = ""
let moralis_Server_URL = ""
let chainID = ""
let moralis_contract_erc721 = ""
let moralis_contract_erc20 = ""
let moralis_contract_minimoose = ""
let moralis_contract_unstaked = ""

if (network === "mainnet") {
    moralis_app_ID = MORALIS_SERVER.mainnet_AppID
    moralis_Server_URL = MORALIS_SERVER.mainnet_serverURL
    chainID = "eth"
    moralis_contract_erc721 = "0xF63063bB20a03B85Bd08d5C1244AF8bA0aEE1B1F"
    moralis_contract_erc20 = "0xE67BC93813071ffF638fAC69184FcE7342c0f043"
    moralis_contract_minimoose = "0xE67BC93813071ffF638fAC69184FcE7342c0f043"
    moralis_contract_unstaked = "0xE67BC93813071ffF638fAC69184FcE7342c0f043"

    // URI For Smart Collections
    SC_1_JSON_URI = "https://ipfs.io/ipfs/QmPKQ4UEPuqUPXJWTsgDUi7mZYWvTrbqAijFA9VbbMxdD1/"
    // SC_1_JSON_URI = "https://ipfs.io/ipfs/QmPKQ4UEPuqUPXJWTsgDUi7mZYWvTrbqAijFA9VbbMxdD1/"
    SC_1_JSON_URI_MORALIS = "https://gateway.moralisipfs.com/ipfs/QmPKQ4UEPuqUPXJWTsgDUi7mZYWvTrbqAijFA9VbbMxdD1/"
    SC_1_IMAGE_URI = "https://ipfs.io/ipfs/QmYznjJxWYWFciAeoGBhbEgennhf9H66gPdidzRGcFHneG/"
    SC_3_JSON_URI = "https://ipfs.io/ipfs/QmZ4wvSvAiXd4WduaUwzXiHXuyr4c4si9ADmETYwWcTjmP/"
    SC_3_JSON_URI_MORALIS = "https://gateway.moralisipfs.com/ipfs/QmZ4wvSvAiXd4WduaUwzXiHXuyr4c4si9ADmETYwWcTjmP/"

    SC_3_IMAGE_URI = "https://ipfs.io/ipfs/QmebqDNUYBN96Yfobeubp7MC46TeJYpznHWwSgyLGMG8Qv/"
    // SC_3_IMAGE_URI = "https://ipfs.io/ipfs/QmVu1RoKEbbjA67Q5zUF3GKiDcX4A2BXPdPEW6DfEKAyED/"
    SC_4_JSON_URI = "https://minimoose.moosesocietynft.io/sidequest/api/"
    SC_4_IMAGE_URI = ""
    ETHERSCAN_BASE_URI = "https://api.etherscan.io/"
}

else if (network === "ropsten") {
    moralis_app_ID = MORALIS_SERVER.ropsten_AppID
    moralis_Server_URL = MORALIS_SERVER.ropsten_serverURL
    chainID = "ropsten"
    moralis_contract_erc721 = ""
    moralis_contract_erc20 = ""
    moralis_contract_minimoose = ""
    moralis_contract_unstaked = ""
}

else if (network === "rinkeby") {
    moralis_app_ID = MORALIS_SERVER.rinkyby_AppID
    moralis_Server_URL = MORALIS_SERVER.rinkyby_serverURL
    chainID = "rinkeby"
    // moralis_contract_erc721 = "0xb1e099bE1F59BCD475f026B563A7D2b11970BdbB"
    // moralis_contract_erc721 = "0xCfd57D4aDa2c693bc7d9C1dCaa3E8C5946769a7b"
    moralis_contract_erc721 = "0x0F12A8705f01beD7485632a7Fcfe728734E95d54"
    moralis_contract_erc20 = "0x99698a3D578075c67C671aAed483B4d0E2Bc86fE"
    // moralis_contract_minimoose = "0x9b80b9FB472cDeAE32117C595d5d609f22F86fd5"
    // moralis_contract_minimoose = "0x7c101f09Cf2dCB7C11E979B294CcF0e111d7Cc35"
    // moralis_contract_minimoose = "0x334D1092844eb1e5E68aae8C5568e2232E37c867"
    moralis_contract_minimoose = "0x2C8D8d70e69f5683A41Ea47aF925512CfbCE7520"
    //moralis_contract_unstaked = "0x3258593631Cb75Ce3282B04FE4075321417ECefB"
    // moralis_contract_unstaked = "0x7c101f09Cf2dCB7C11E979B294CcF0e111d7Cc35" // New Unstake Contract
    moralis_contract_unstaked = "0x45D286666bfe7cb4BdB2f62b7202CFC6D5ba92f8" // New Unstake Contract

    // URI For Smart Collections
    SC_1_JSON_URI = "https://ipfs.io/ipfs/QmPKQ4UEPuqUPXJWTsgDUi7mZYWvTrbqAijFA9VbbMxdD1/"
    SC_1_JSON_URI_MORALIS = "https://gateway.moralisipfs.com/ipfs/QmPKQ4UEPuqUPXJWTsgDUi7mZYWvTrbqAijFA9VbbMxdD1/"
    SC_1_IMAGE_URI = "https://ipfs.io/ipfs/QmYznjJxWYWFciAeoGBhbEgennhf9H66gPdidzRGcFHneG/"
    SC_3_JSON_URI = "https://ipfs.io/ipfs/QmZ4wvSvAiXd4WduaUwzXiHXuyr4c4si9ADmETYwWcTjmP/"
    SC_3_JSON_URI_MORALIS = "https://gateway.moralisipfs.com/ipfs/QmZ4wvSvAiXd4WduaUwzXiHXuyr4c4si9ADmETYwWcTjmP/"
    // SC_3_IMAGE_URI = "https://ipfs.io/ipfs/QmVu1RoKEbbjA67Q5zUF3GKiDcX4A2BXPdPEW6DfEKAyED/"
    SC_3_IMAGE_URI = "https://ipfs.io/ipfs/QmebqDNUYBN96Yfobeubp7MC46TeJYpznHWwSgyLGMG8Qv/"
    SC_4_JSON_URI = "https://minimoose.moosesocietynft.io/sidequest/api/"
    SC_4_IMAGE_URI = ""
    ETHERSCAN_BASE_URI = "https://api-rinkeby.etherscan.io"
}

const URI = {
    SC_1_JSON_URI: SC_1_JSON_URI,
    SC_1_JSON_URI_MORALIS: SC_1_JSON_URI_MORALIS,
    SC_1_IMAGE_URI: SC_1_IMAGE_URI,
    SC_3_JSON_URI: SC_3_JSON_URI,
    SC_3_JSON_URI_MORALIS: SC_3_JSON_URI_MORALIS,
    SC_3_IMAGE_URI: SC_3_IMAGE_URI,
    SC_4_JSON_URI: SC_4_JSON_URI,
    SC_4_IMAGE_URI: SC_4_IMAGE_URI,
    ETHERSCAN_BASE_URI: ETHERSCAN_BASE_URI
}

const CONFIG = {
    moralis_app_ID: moralis_app_ID,
    moralis_Server_URL: moralis_Server_URL,
    moralis_chain: chainID,
    smart_contract_erc721: moralis_contract_erc721,
    smart_contract_erc20: moralis_contract_erc20,
    smart_contract_minimoose: moralis_contract_minimoose,
    smart_contract_unstaked: moralis_contract_unstaked,
    chainID: chainID,
    URI: URI
}

export { CONFIG }