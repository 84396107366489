import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Figure,
  Modal,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { CONFIG } from "../../../config.js";
import { Connectwallethandler } from "../../../store/actions/thunks/connectWalletHandler.js";
import FooterFooter from "../../components/footerfooter";
//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from "../../Styles";
import NFTCards from "./component/NFTCards.js";
import StakedNFTCards from "./component/StakedNFTCards.js";
import "./dashboard.css";
import unStackingJsonData from "./data.json";
import { ABI, DashboardFunctions } from "./functions";
import mooseipfsdata from "./mooseipfs.json";


//SWITCH VARIABLE FOR PAGE STYLE
const theme = "GREY"; //LIGHT, GREY, RETRO

const Index = ({ handleClick }) => {
  const [show, setShow] = useState(false);
  const [usersummary, setUserSummary] = useState({
    daily_reward: 0,
    my_total_og_new_nft: 0, // Newly Minted NFT
    my_total_minimoose_nft: 0, // Minimoose NFT
    my_total_og_unstaked_nft: 0, // After Unstake Process We Get 2 OG NFTS, this is that thing
    my_total_og_staked_nft: 0, // The OG NFTs that we have staked and that have been sent to Smart Contract
    pending_reward: 0,
    total_trax: 0,
    total_nft_minted: 0,
    getTotalClaimable: 0
  });
  const [showStakeButton, setShowStakeButton] = useState(false);
  const handleCloseStakeButton = () => setShowStakeButton(false);
  const handleShowStakeButton = () => setShowStakeButton(true);

  const [showUnStakeButton, setShowUnStakeButton] = useState(false);
  const handleCloseUnStakeButton = () => setShowUnStakeButton(false);
  const handleShowUnStakeButton = () => {
    console.log("filteredImgUnStack", filteredImgUnStack);
    setShowUnStakeButton(true);
  };

  const [myNFTs, setMYNFT] = useState([]);
  const [myNFTsClubbed, setMYNFTClubbed] = useState([]);
  const [myStakedNFTs, setMYSTAKEDNFT] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    authenticate,
    isAuthenticated,
    user,
    Moralis,
    setUserData,
    userError,
    isWeb3Enabled,
    account,
    ...xyz
  } = useMoralis();
  const [mintAmount, setMintAmount] = useState({
    mintQuantity: 0,
  });
  useEffect(() => {
    console.log(mintAmount);
    console.log(myNFTs);
  }, [mintAmount, myNFTs]);
  useEffect(() => {
    console.log("usersummary", usersummary);
  }, [usersummary]);
  useEffect(() => {
    console.log("accountswing", account);
    let getAccount = window.localStorage.getItem("accountName");
    if ((getAccount != account) && (account !== null)) {
      console.log("getAccountandaccount", getAccount, account)
      window.localStorage.setItem("accountName", account);
      alert(`New wallet address is ${account} `)
      //HARDCODE
      // let myWalletAddressTemp = "0x21c5eb0216ad36cd38503a90e66fe9ef30004f1d"
      let myWalletAddressTemp = account
      console.log("accountswingmyWalletAddressTemp", myWalletAddressTemp);
      fetchNFTNOW(myWalletAddressTemp);
    }
  }, [account]);
  useEffect(() => {
    console.log("Configuration Details", CONFIG);
    console.log("isAuthenticate=", isAuthenticated);


    let getAccount = window.localStorage.getItem("accountName");
    if ((getAccount != null)) {
      // HARDCODE
      // let myWalletAddressTemp = "0x21c5eb0216ad36cd38503a90e66fe9ef30004f1d"
      let myWalletAddressTemp = getAccount

      fetchNFTNOW(myWalletAddressTemp);
    }


    console.log("MYNETWORKCHAIN", CONFIG.chainID)

  }, []);
  useEffect(() => {
    console.log(`isWeb3Enabled${isWeb3Enabled}${isAuthenticated}`);
  }, [isWeb3Enabled, isAuthenticated]);

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifyInfo = (message) =>
    toast.info(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifyError = (message) =>
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  async function handleSide(option) {
    const { label, value } = option;
    console.log("value=", value, "label=", label);
    console.log(label);
    await setMintAmount({
      ...mintAmount,
      mintQuantity: value,
    });
  }
  // FOR MINTER
  const [walletAddress, setWallet] = useState("");
  // FOR MINTER
  const { data, error, fetch, isFetching, isLoading } =
    useWeb3ExecuteFunction();
  // mintMetaMooseMansion
  async function mintNFT2(val) {
    const etherValue = val * 0.01;
    let options = {
      chain: CONFIG.chainID,
      contractAddress: CONFIG.smart_contract_erc721,
      functionName: "mintMetaMooseMansion",
      abi: ABI.mintMetaMooseMansion,
      params: {
        _count: val,
      },
      msgValue: Moralis.Units.ETH(etherValue),
    };
    // await Moralis.enableWeb3();
    console.log("mintUser=>", user);
    try {
      const mintTransaction = await Moralis.executeFunction(options);
      console.log(
        "mintTransaction=>",
        mintTransaction,
        "mintTransactionhash",
        mintTransaction.hash
      );
      notifyInfo("Please wait for confirmation");
      await mintTransaction.wait();
      notifySuccess("Please reload after sometime to get the minted NFT");
    } catch (e) {
      console.log("mintError=>", e);
      notifyError("Oops some problem occured! Please try again later!");
    }
    if (
      typeof error?.code == "undefined" &&
      typeof error?.data != "undefined"
    ) {
      notifySuccess("Please Wait For Sometime Transaction is Successful");
    } else if (typeof error?.code != "undefined") {
      notifyError("There is some error Please Try Again Later");
    }
  }
  async function stakeNFT(nft1_ID1, nft2_ID2) {
    handleCloseStakeButton();
    console.log(
      "Staking NFT1 =>",
      filteredImg.filteredImage1.token_id,
      "| Staking NFT2 =>",
      filteredImg.filteredImage2.token_id
    );
    let abi_stake_nft = ABI.stakeMoose;
    let options = {
      chain: CONFIG.chainID,
      contractAddress: CONFIG.smart_contract_erc721,
      functionName: "stakeMoose",
      abi: abi_stake_nft,
      params: {
        _tokenId1: filteredImg.filteredImage1.token_id,
        _tokenId2: filteredImg.filteredImage2.token_id,
      },
    };
    // await Moralis.enableWeb3();
    console.log("before fetch");

    try {
      const stakeTransaction = await Moralis.executeFunction(options);
      notifyInfo("Please wait for confirmation");
      await stakeTransaction.wait();

      console.log("after fetch");
      console.log("stakeTransaction", stakeTransaction);
      notifySuccess("Staked NFTs will be reflected after sometime");
    } catch (e) {
      notifyError("Oops some problem occured! Try again later");
    }
    console.log("error", error);
    console.log("data", data);
  }

  async function unstakeNFT() {
    console.log("HI THERE", filteredImgUnStack);
    console.log("HI THERE Executing unstakeNft function", filteredImgUnStack);
    handleCloseUnStakeButton();
    // console.log("Staking NFT1 =>", filteredImg.filteredImage1.token_id);
    // console.log("Staking NFT2 =>", filteredImg.filteredImage2.token_id);
    let options = {
      chain: CONFIG.chainID,
      contractAddress: CONFIG.smart_contract_unstaked,
      functionName: "award3",
      abi: ABI.award3,
      params: {
        tokenURI1: DashboardFunctions.getJSONURIofNFTfromTokenID_SC4(
          filteredImgUnStack.filteredImage1.token_Id1
          // 'https://minimoose.moosesocietynft.io/sidequest/api/'.token_Id1
        ),
        tokenURI2: DashboardFunctions.getJSONURIofNFTfromTokenID_SC4(
          filteredImgUnStack.filteredImage1.token_Id2
          // 'https://minimoose.moosesocietynft.io/sidequest/api/'.token_Id2
        ),
        //to: filteredImgUnStack.filteredImage1.owner,
      },
    };
    // let options = {
    //   chain: CONFIG.chainID,
    //   contractAddress: CONFIG.smart_contract_unstaked,
    //   functionName: "award3",
    //   abi: ABI.award3,
    //   params: {
    //     tokenURI1: DashboardFunctions.getJSONURIofNFTfromTokenID_SC1(
    //       //filteredImgUnStack.filteredImage1.token_Id1
    //       'https://minimoose.moosesocietynft.io/sidequest/api/'.token_Id1
    //     ),
    //     tokenURI2: DashboardFunctions.getJSONURIofNFTfromTokenID_SC1(
    //       //filteredImgUnStack.filteredImage1.token_Id2
    //       'https://minimoose.moosesocietynft.io/sidequest/api/'.token_Id2
    //     ),
    //     //to: filteredImgUnStack.filteredImage1.owner,
    //   },
    // };
    console.log("0000", {
      tokenURI1: DashboardFunctions.getJSONURIofNFTfromTokenID_SC1(
        filteredImgUnStack.filteredImage1.token_Id1
        // 'https://minimoose.moosesocietynft.io/sidequest/api/'.token_Id1
      ),
      tokenURI2: DashboardFunctions.getJSONURIofNFTfromTokenID_SC1(
        filteredImgUnStack.filteredImage1.token_Id2
        // 'https://minimoose.moosesocietynft.io/sidequest/api/'.token_Id2
      ),
      to: filteredImgUnStack.filteredImage1.owner,
    });
    // await Moralis.enableWeb3();
    console.log("Fetching");

    try {
      const unstakeTransaction = await Moralis.executeFunction(options);
      notifyInfo("Please wait for confirmation");
      await unstakeTransaction.wait();

      console.log("after fetch");
      console.log("UnstakeTransaction", unstakeTransaction);
      notifySuccess(
        "UnStaked NFTs will Be Reflected After Sometime! Please Be Patient"
      );
      console.log("Unstake FUnction", unstakeTransaction);
    } catch (e) {
      console.log(e)
      notifyError("Oops Some Problem Occured! Try Again Later");
    }
    // await fetch({
    //   params: options,
    // });
    console.log("Fetching");
    console.log("error", error);
    console.log("data", data);
  }

  async function fetchNFTNOW(myWalletAddressTemp) {

    var aaa1 = window.localStorage.getItem("accountName");
    console.log("zzzaaaa", aaa1)
    console.log("zzzwalletAddress", walletAddress)
    console.log("zzzxyz", xyz);

    // if (!isAuthenticated || !account) {
    //   console.log("INSIDEFUNCTIONisAuthenticated",isAuthenticated)
    //   console.log("INSIDEFUNCTION")
    //   await authenticate2()
    //   console.log("INSIDEFUNCTIONisAuthenticated",isAuthenticated)
    // }
    // STEP 1 : Work On Loading Icon
    // STEP 2 : Work On MY NFTs [BASEMOOSE+MINIOOSE+UNSTKED_MOOSE]
    // STEP 3 : Work On MY STAKED NFTs [Staked MOOSE - UnSTAKED MOOSE]
    let element = document.getElementById("fetchNFTicon");
    element.classList.add("fa-spin");
    setTimeout(() => {
      element.classList.remove("fa-spin");
    }, 2000);
    await Moralis.enableWeb3();

    // console.log("enableWeb3",await Moralis.enableWeb3());
    // FETCHING FROM SMART CONTRACT 1 : OG MOOSE

    // HARDCODE the address
    const options = {
      chain: CONFIG.chainID,
      address: account,
      token_address: CONFIG.smart_contract_erc721,
    };

    // Testing Server--------------------------------------------------------

    console.log("xxxxx2222223")
    let xx2 = await DashboardFunctions.getImageFromBrianServer(10);
    console.log("xxxxx2222223", xx2)


    const options1 = {
      chain: "eth",
      address: "0xF63063bB20a03B85Bd08d5C1244AF8bA0aEE1B1F",
      token_address: "0xF63063bB20a03B85Bd08d5C1244AF8bA0aEE1B1F",
    };
    const rinkybyNFTs1 = await Moralis.Web3API.account.getNFTsForContract(
      options1);
    console.log("TEST NOW PLEASE", rinkybyNFTs1)

    const options2 = { q: "image", chain: CONFIG.chainID, filter: "name" };
    const NFTs111 = await Moralis.Web3API.token.searchNFTs(options2);
    console.log("TEST NOW PLEASE=>NFTs111", NFTs111)
    // Testing Server----------------------------------------------------------
    //-------------------------------------------------------------------------
    //---------------------------------- TOTAL OG_NEW NFT ---------------------
    //-------------------------------------------------------------------------

    const rinkybyNFTs = await Moralis.Web3API.account.getNFTsForContract(
      options
    );

    console.log("TEST NOW PLEASE", rinkybyNFTs)
    rinkybyNFTs.result.map((nft, index) => {
      rinkybyNFTs.result[index].image =
        DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC1(nft.token_id);
      rinkybyNFTs.result[index].nft_name = "Moose Society";
      rinkybyNFTs.result[index].collection_id = nft.token_id;
    });
    console.log("TEST NOW PLEASE", rinkybyNFTs)
    //-------------------------------------------------------------------------
    //------------------------ TOTAL MINIMOOSE NFT MINTED ---------------------
    //-------------------------------------------------------------------------
    // FETCHING FROM SMART CONTRACT 3 : MINIMOOSE
    const optionsMinimoose = {
      chain: CONFIG.chainID,
      address: account,
      token_address: CONFIG.smart_contract_minimoose,
    };

    const rinkybyNFTsMiniMoose =
      await Moralis.Web3API.account.getNFTsForContract(optionsMinimoose);
    console.log("2223", rinkybyNFTsMiniMoose);

    rinkybyNFTsMiniMoose.result.map(async (nft, index) => {

      rinkybyNFTsMiniMoose.result[index].image =
        DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC3(nft.token_id);
      // rinkybyNFTsMiniMoose.result[index].image =
      //   await DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC3_test(nft.token_id);
      rinkybyNFTsMiniMoose.result[index].nft_name = "Mini Moose";
      rinkybyNFTsMiniMoose.result[index].collection_id = nft.token_id;
    });

    //-------------------------------------------------------------------------
    //------------------------ TOTAL OG_UNSTAKED NFT ---------------------
    //-------------------------------------------------------------------------
    // FETCHING FROM SMART CONTRACT 4 : UNSTAKED ORIGIN MOOSE
    const optionsSC4 = {
      chain: CONFIG.chainID,
      address: account,
      token_address: CONFIG.smart_contract_unstaked,
    };
    const rinkybyNFTsSC4 = await Moralis.Web3API.account.getNFTsForContract(
      optionsSC4
    );

    rinkybyNFTsSC4.result.map(async (nft, index) => {
      let nftjsonURI = nft.token_uri;
      let splitArray = nftjsonURI.split("/");
      let lastelement = splitArray[splitArray.length - 1];
      let seperatewithdot = lastelement.split(".");
      let gettokenID = seperatewithdot[0];
      console.log("splitArray", splitArray);
      console.log("splitArraylastelement", lastelement);
      console.log("splitArraylastelementgetNumbergettokenID", gettokenID);
      rinkybyNFTsSC4.result[index].oldtoken_id = gettokenID;
      // rinkybyNFTsSC4.result[index].image =
      // DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC1(nft.oldtoken_id);
      rinkybyNFTsSC4.result[index].image =
        await DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC4(nft.oldtoken_id);
      rinkybyNFTsSC4.result[index].nft_name = "Moose Society(Unstaked)";
      rinkybyNFTsSC4.result[index].collection_id = nft.oldtoken_id;
    });
    console.log("rinkybyNFTsSC4", rinkybyNFTsSC4);


    const rinkybyNFTs111 = await Moralis.Web3API.account;
    console.log("Moralis Web3API", rinkybyNFTs111);
    console.log("rinkybyNFTs", rinkybyNFTs);
    console.log("rinkybyNFTsMiniMoose", rinkybyNFTsMiniMoose);
    console.log("rinkybyNFTs.length", rinkybyNFTs?.result?.length);
    console.log(
      "rinkybyNFTsMiniMoose.length",
      rinkybyNFTsMiniMoose?.result?.length
    );
    setMYNFT(rinkybyNFTs.result);
    console.log(
      "CLubbed NFT",
      rinkybyNFTs.result.concat(rinkybyNFTsMiniMoose.result)
    );
    console.log("optionsSC4 optionsSC4", rinkybyNFTsSC4.result);
    setMYNFTClubbed(
      rinkybyNFTs.result.concat(
        rinkybyNFTsMiniMoose.result,
        rinkybyNFTsSC4.result
      )
    );


    setJsonData(rinkybyNFTs.result);
    // Fetching dummy data for unstaking
    setUnstackJson(rinkybyNFTs.result);
    console.log("testconsole", {
      ...usersummary,
      my_total_og_new_nft: rinkybyNFTs.result.length,
      my_total_minimoose_nft: rinkybyNFTsMiniMoose?.result?.length,
      my_total_og_unstaked_nft: rinkybyNFTsSC4.result.length,
    });
    console.log(myNFTs);
    setWallet(rinkybyNFTs?.result?.owner_of);
    console.log(walletAddress);
    console.log("--------------------------------", 0, rinkybyNFTs);
    if (rinkybyNFTs?.result?.length >= 1) {
      console.log("result 0", rinkybyNFTs.result[0].owner_of);
      var xxx1 = await fetchDataEtherScanMyStakedNFT(
        rinkybyNFTs.result[0].owner_of,
        rinkybyNFTsSC4.result
      );
      console.log("usersummaryxxx1=>", xxx1);
    }
    console.log("xyzaccount10", account);
    let fetchEtherScanData = await DashboardFunctions.fetchDataEtherScan();
    console.log("sssssssssssss", fetchEtherScanData);
    //-------------------------------------------------------------------------
    //---------------------------------- TOTAL NFT MINTED ---------------------
    //-------------------------------------------------------------------------
    const readOptions = {
      contractAddress: CONFIG.smart_contract_erc721,
      functionName: "totalSupply",
      abi: ABI.totalSupply,
    };

    // const message ="0x22"
    const message = await Moralis.executeFunction(readOptions);
    // const message = await Moralis.executeFunction(readOptions);
    console.log("totalSupplyFetched", message._hex);
    console.log("totalSupplyFetched", parseInt(message._hex, 16));
    let totalSupplyfetched = parseInt(message._hex, 16);
    console.log("totalSupplyFetched", totalSupplyfetched);
    //-------------------------------------------------------------------------
    //------------------ getTotalClaimable Minted Ki Calculation --------------
    //-------------------------------------------------------------------------
    console.log("xyzaccount12", account);
    let tempUser = ""
    console.log("myWalletAddressTemp", myWalletAddressTemp)
    const readOptions1 = {
      contractAddress: CONFIG.smart_contract_erc20,
      functionName: "getTotalClaimable",
      abi: ABI.getTotalClaimable,
      params: {
        user: myWalletAddressTemp,
      },
    };


    const message1 = await Moralis.executeFunction(readOptions1);
    // let getTotalClaimable = parseInt((message1._hex), 16);
    console.log("getTotalClaimable", account);
    console.log("getTotalClaimable", parseInt(message1._hex, 16));
    let getTotalClaimable = parseInt(message1._hex, 16) / Math.pow(10, 18);
    // console.log("totalSupplyFetched", message._hex);
    // console.log("totalSupplyFetched", parseInt(message._hex, 16));
    console.log("getTotalClaimable", getTotalClaimable);
    let totalTrax =
      getTotalClaimable + rinkybyNFTsMiniMoose?.result?.length * 1800;
    console.log("valueY", rinkybyNFTsMiniMoose?.result?.length * 1800);
    console.log("totalTrax", totalTrax);
    totalTrax = totalTrax.toFixed(2);

    //-------------------------------------------------------------------------
    //------------------ getTotalClaimable Minted Ki Calculation --------------
    //-------------------------------------------------------------------------



    //-------------------------------------------------------------------------
    //------------------ TESTING getNFTTransfers---------------- --------------
    //-------------------------------------------------------------------------

    const options3 = {
      chain: CONFIG.chainID,
      address: CONFIG.smart_contract_unstaked,
      limit: "10",
    };

    console.log("xyzaccount13", account);

    const transfersNFT = await Moralis.Web3API.account.getNFTTransfers(
      options3
    );
    console.log("transfers", transfersNFT);






    // console.log("getTotalClaimableaa1", aa1)
    // console.log("getTotalClaimable", getTotalClaimableSlash18)
    // console.log("totalSupplyFetched", totalSupplyfetched);
    // set Total NFT Minted eg 50/5000
    // let daily_reward = rinkybyNFTs.result.length

    //-------------------------------------------------------------------------
    //------------------ TESTING getNFTTransfers---------------- --------------
    //-------------------------------------------------------------------------


    //----------------------STARTING-------------------------------------------
    //------------------ get TOTAL TRAX(balance Of)---------------- --------------
    //-------------------------------------------------------------------------

    console.log("balancesaccount", myWalletAddressTemp, account)

    const optionsx = { chain: CONFIG.chainID, address: myWalletAddressTemp }
    const balances = await Moralis.Web3API.account.getTokenBalances(optionsx);

    // alert(`Hi ${balances}`)
    console.log("balances", balances)

    // let obj = balances.find(o => o.token_address === CONFIG.smart_contract_erc20);
    var __FOUND = -1;
    for (var i = 0; i < balances.length; i++) {
      if (balances[i].token_address == CONFIG.smart_contract_erc20.toLowerCase()) {
        //__FOUND is set to the index of the element
        __FOUND = i;
        break;
      }
    }

    // myWalletAddressTemp
    console.log("balancesTRAX__FOUND", __FOUND)
    if (__FOUND != -1) {
      console.log("balancesTRAX", CONFIG.smart_contract_erc20.toLowerCase())
      console.log("balancesTRAX", balances[__FOUND].balance / Math.pow(10, 18))
      totalTrax = balances[__FOUND].balance / Math.pow(10, 18)
      totalTrax = totalTrax.toFixed(2);
    } else {
      totalTrax = 0;
      console.log("balancesTRAX", totalTrax)
    }

    // let obj = balances.find(o => o.token_address == "0xE67BC93813071ffF638fAC69184FcE7342c0f043");
    // console.log("balancesTRAX", obj)

    // const readOptions = {
    //   contractAddress: CONFIG.smart_contract_erc721,
    //   functionName: "totalSupply",
    //   abi: ABI.totalSupply,
    // };

    // // const message ="0x22"
    // const message = await Moralis.executeFunction(readOptions);
    // // const message = await Moralis.executeFunction(readOptions);
    // console.log("totalSupplyFetched", message._hex);
    // console.log("totalSupplyFetched", parseInt(message._hex, 16));






    //-----------------------------ENDING-----------------------------------------
    //------------------ get TOTAL TRAX(balance Of---------------- --------------
    //-------------------------------------------------------------------------




    console.log("xyzaccount14", account);

    setUserSummary({
      ...usersummary,
      my_total_og_new_nft: rinkybyNFTs.result.length,
      my_total_minimoose_nft: rinkybyNFTsMiniMoose?.result?.length,
      my_total_og_unstaked_nft: rinkybyNFTsSC4.result?.length,
      my_total_og_staked_nft: xxx1,
      total_nft_minted: totalSupplyfetched,
      getTotalClaimable: getTotalClaimable,
      total_trax: totalTrax,
    });
  }
  async function fetchDataEtherScanMyStakedNFT(
    myWalletAddress,
    unstaked_og_nfts
  ) {
    console.log(
      "usersummary=myWalletAddress",
      myWalletAddress,
      unstaked_og_nfts
    );
    let url = `${CONFIG.URI.ETHERSCAN_BASE_URI}/api?module=account&action=tokennfttx&contractaddress=${CONFIG.smart_contract_erc721.toLowerCase()}&page=1&offset=5000&sort=asc&apikey=BTPMZFG4Q5JD2WK6VTGRJVS5CTIBMV9XB4`;
    try {
      const response = await axios.get(url);
      let tempArray = response.data.result;
      console.log("response.data", response.data);
      console.log("response.data.result", response.data.result);
      console.log("tempArray", tempArray);
      let myFilteredArray = tempArray.filter(function (e) {
        // walletAddress
        if (
          e.from.toLowerCase() === myWalletAddress &&
          e.to.toLowerCase() === CONFIG.smart_contract_erc721.toLowerCase()
        ) {
          return true;
        } else {
          return false;
        }
      });
      console.log("xyzaccount15", account);

      console.log("My Staked NFTs");
      console.log("----------------------");
      console.log("myFilteredArray", myFilteredArray);

      myFilteredArray.map((nft, index) => {
        // myFilteredArray.result[index].image = DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC1(nft.token_id)
        // myFilteredArray.result[index].nft_name = "OG Staked NFT"
        myFilteredArray[index].collection_id = nft.tokenID;
      });
      console.log("myFilteredArray", myFilteredArray);

      const myFilteredArray_MODIFIED = myFilteredArray.filter(
        (x) => !unstaked_og_nfts.some((y) => x.tokenID === y.oldtoken_id)
      );
      console.log("TestmyStakedNFTsmyFiltered", myFilteredArray);
      console.log("TestmyStakedNFTsunstaked", unstaked_og_nfts);
      console.log("TestmyStakedNFTsresult--====", myFilteredArray_MODIFIED);
      await setMYSTAKEDNFT(myFilteredArray_MODIFIED);
      // Code to Get All The Pairs of Staked NFTs
      let gotPairsOfNFT = DashboardFunctions.getPairsOfNFT(myFilteredArray);
      let gotPairsOfNFTNew = DashboardFunctions.getPairsOfNFT(
        myFilteredArray_MODIFIED
      );
      console.log("gotPairsOfNFT", gotPairsOfNFT);

      // Check If we are getting the data or not
      // setUnstackJson(gotPairsOfNFT);
      setUnstackJson(gotPairsOfNFTNew);
      console.log("myFilteredArray", myStakedNFTs);
      console.log("myFilteredArray.Length", myStakedNFTs.length);
      // daily_reward: myNFTs.length * 10,
      console.log("usersummary", myStakedNFTs.length);
      return myFilteredArray_MODIFIED.length;
      // setUserSummary({
      //   ...usersummary,
      //   my_total_og_staked_nft: myStakedNFTs.length,
      // });
      // console.log("userSummary", usersummary);
      // console.log("----------------------");
    } catch (error) {
      console.error(error);
      return 0;
    }
  }
  async function eighteenhundredTraxCalculation(
    myWalletAddress,
    unstaked_og_nfts
  ) {
    console.log(
      "usersummary=myWalletAddress",
      myWalletAddress,
      unstaked_og_nfts
    );
    let url = `${CONFIG.URI.ETHERSCAN_BASE_URI}/api?module=account&action=tokennfttx&contractaddress=${CONFIG.smart_contract_erc721.toLowerCase()}&page=1&offset=5000&sort=asc&apikey=BTPMZFG4Q5JD2WK6VTGRJVS5CTIBMV9XB4`;
    try {
      const response = await axios.get(url);
      let tempArray = response.data.result;
      console.log("response.data", response.data);
      console.log("response.data.result", response.data.result);
      console.log("tempArray", tempArray);
      let myFilteredArray = tempArray.filter(function (e) {
        // walletAddress
        if (
          e.from.toLowerCase() === myWalletAddress &&
          e.to.toLowerCase() === CONFIG.smart_contract_erc721.toLowerCase()
        ) {
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  //-----------------------------------------------------------------------------
  async function authenticate2() {
    let element = document.getElementById("fetchStakedNFTicon");
    element.classList.add("fa-spin");
    setTimeout(() => {
      element.classList.remove("fa-spin");
    }, 2000);
    console.log("xyzaccount", account);
    await authenticate({ signingMessage: "Sign in with your wallet" });
    window.localStorage.setItem("accountName", account);

    console.log("xyz", xyz);
    // await Moralis.enableWeb3();
    setWallet(user.attributes.ethAddress);
    console.log("user.attributes.ethAddress", user.attributes.ethAddress);
    console.log("user.get(ethAddress)", user.get("ethAddress"));

    console.log("walletAddress", walletAddress)
    console.log("xyz", xyz);
    console.log("xyzaccount", account);
    if (userError) {
      console.log("userError", userError);
    }
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Connectwallethandler(authenticate2));
  }, [authenticate2]);
  /*
  This is a functionality of Replacing The Image And Stake Moose Functionality
  */
  //  stacking section area
  const [jsonData, setJsonData] = useState([]);
  const [filteredImg, setFilteredImg] = useState({
    filteredImage1: [],
    filteredImage2: [],
  });
  const [selectedBox, setSelectedBox] = useState(null);
  const selectImage = (token_id) => {
    const findImg = jsonData.find((pd) => {
      return pd.token_id === token_id;
    });

    if (filteredImg.filteredImage1.length < 1 && selectedBox === "1") {
      condition(findImg, setFilteredImg, setJsonData, "1");
    } else if (selectedBox === "1") {
      condition(
        findImg,
        setFilteredImg,
        setJsonData,
        "1",
        filteredImg.filteredImage1
      );
    }

    if (filteredImg.filteredImage2.length < 1 && selectedBox === "2") {
      condition(findImg, setFilteredImg, setJsonData, "2");
    } else if (selectedBox === "2") {
      condition(
        findImg,
        setFilteredImg,
        setJsonData,
        "2",
        filteredImg.filteredImage2
      );
    }
  };
  // Reusable function for stacking image
  const condition = (
    findImg,
    setFilter,
    setData,
    filterImageType,
    filterImage
  ) => {
    const filter = jsonData.filter((pd) => pd.token_id !== findImg.token_id);
    filterImageType === "1" &&
      setFilter({ ...filteredImg, filteredImage1: findImg });
    filterImageType === "2" &&
      setFilter({ ...filteredImg, filteredImage2: findImg });
    setData(filterImage ? [filterImage, ...filter] : filter);
  };

  // ************************* unstacking section area ****************************************
  const [unstackJson, setUnstackJson] = useState(unStackingJsonData);
  const [filteredImgUnStack, setFilteredImgUnStack] = useState({
    filteredImage1: [],
  });
  // console.log("unstacking", filteredImgUnStack)
  // console.log("waLLETaDDRESS", walletAddress)
  // console.log("unstacking", filteredImgUnStack.filteredImage1)
  const [selectedBoxUnstack, setSelectedBoxUnstack] = useState(null);
  const selectImageUnstack = (token_id) => {
    const findImg = unstackJson.find((pd) => {
      return pd.id === token_id;
      // return pd.id === id;
    });
    if (
      filteredImgUnStack.filteredImage1?.length < 1 &&
      selectedBoxUnstack === "3"
    ) {
      conditionUnStack(findImg, setFilteredImgUnStack, setUnstackJson, "3");
    } else {
      conditionUnStack(
        findImg,
        setFilteredImgUnStack,
        setUnstackJson,
        "3",
        filteredImgUnStack.filteredImage1
      );
    }
  };
  // Reusable function
  const conditionUnStack = (
    findImg,
    setFilter,
    setData,
    filterImageType,
    filterImage
  ) => {
    // const filter = unstackJson.filter((pd) => pd.token_id !== findImg.token_id);

    const filter = unstackJson.filter((pd) => pd.id !== findImg.id);
    filterImageType === "3" &&
      setFilter({ ...filteredImgUnStack, filteredImage1: findImg });
    setData(filterImage ? [filterImage, ...filter] : filter);
  };

  const [stacking, setStacking] = useState(false);
  const [unStacking, setUnStacking] = useState(false);
  // image replace and select box handler

  const handleReplaceImage = (type, stackType) => {
    if (stackType === "stack") {
      setSelectedBox(type);
      setShow(true);
      setUnStacking(false);
      setStacking(true);
    } else if (stackType === "unstack") {
      setSelectedBoxUnstack(type);
      setShow(true);
      setUnStacking(true);
      setStacking(false);
    }
  };

  const customStyles = {
    option: (base, state) => ({
      ...base,
      background: "#fff",
      color: "#333",
      borderRadius: state.isFocused ? "0" : 0,
      "&:hover": {
        background: "#eee",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    control: (base, state) => ({
      ...base,
      padding: 2,
    }),
  };
  return (
    <>
      <div className="greyscheme">
        <StyledHeader theme={theme} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="dark"
          pauseOnHover
        />
        <div className="greyscheme">
          <section className="container">
            <div className="row mt-4">
              <div className="col-md-4 text-center">
                <h5>+{usersummary.my_total_og_new_nft * 10} TRAX/ day</h5>
                <span>+Daily Reward</span>
              </div>

              {/* <div className="col-md-4 text-center">
                <h5>{usersummary.total_trax} TRAX</h5>
                <span>Total TRAX Balance</span>
              </div> */}
              <div className="col-md-4 text-center">
                <h5>{usersummary.getTotalClaimable.toFixed(2)} TRAX</h5>
                <span>Total TRAX  Earned</span>
                {/* <p>
                  {usersummary.my_total_og_new_nft}{" "}
                  <span className="white2">(New Minted)</span> +{" "}
                  {usersummary.my_total_og_staked_nft}{" "}
                  <span className="purple">(Staked)</span> <br />
                  {usersummary.my_total_minimoose_nft}{" "}
                  <span className="white2">(MiniMoose)</span> +{" "}
                  {usersummary.my_total_og_unstaked_nft}{" "}
                  <span className="purple">(Unstaked)</span>{" "}
                </p> */}
                {/* <span>Total NFTs</span> */}
                {/* <h5>
                  {usersummary.my_total_og_new_nft +
                    usersummary.my_total_og_staked_nft +
                    usersummary.my_total_minimoose_nft +
                    usersummary.my_total_og_unstaked_nft}
                </h5> */}
              </div>
              <div className="col-md-4 text-center">
                <h5>{usersummary.total_trax} TRAX</h5>
                <span>Total TRAX Balance</span>
              </div>
              <hr className="red" />
            </div>
            <div className="row">
              <div className="col-md-12 mt-3">
                <h3 className="text-center">Dashboard</h3>
                <div id="tabs2">
                  <Tabs fill defaultActiveKey="totalNFT">
                    <Tab eventKey="totalNFT" title="All NFT">
                      <hr style={{ marginBottom: "-50px" }} className="horizontal_row" />
                      <div className="greyscheme">
                        <StyledHeader theme={theme} />
                        <section className="container ">
                          <div className="Minter text-center d-flex flex-column justify-content-center align-items-center">
                            <h1>Mint your NFT</h1>
                            <div>
                              <h3>
                                Total Minted : {usersummary.total_nft_minted}
                                /5000
                              </h3>
                              <Select
                                styles={customStyles}
                                onChange={handleSide}
                                options={[
                                  { value: "1", label: "1" },
                                  { value: "2", label: "2" },
                                  { value: "3", label: "3" },
                                  { value: "4", label: "4" },
                                  { value: "5", label: "5" },
                                  { value: "6", label: "6" },
                                  { value: "7", label: "7" },
                                  { value: "8", label: "8" },
                                  { value: "9", label: "9" },
                                  { value: "10", label: "10" },
                                ]}
                              />
                              <br />
                              <button
                                id="walletButton"
                                className="btn-main"
                                onClick={() =>
                                  mintNFT2(mintAmount.mintQuantity)
                                }
                              >
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Mint
                                Now &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              </button>
                            </div>
                            <div className="">
                              <br />
                              {/* <h4
                                className="red"
                                style={{ fontWeight: "lighter" }}
                              >
                                Check Gas fees
                              </h4> */}
                            </div>
                            {walletAddress?.length > 0 &&
                              "Connected Address: " + walletAddress}
                            <br />
                            <br />
                            {walletAddress?.length === 0 && (
                              <p className="metamask_connect_warning">connect to metamask to start minting</p>
                            )}
                          </div>
                        </section>
                      </div>
                      <hr style={{ marginTop: "-50px" }} />
                      <h3>
                        {" "}
                        My NFTs{" "}
                        <span
                          className="cursorClass"
                          // HARDCODE
                          // onClick={() => fetchNFTNOW("0x21c5eb0216ad36cd38503a90e66fe9ef30004f1d")}
                          onClick={() => fetchNFTNOW(window.localStorage.getItem("accountName"))}
                        >
                          <i
                            id="fetchNFTicon"
                            className="fa fa-refresh"
                            aria-hidden="true"
                          />
                        </span>{" "}
                      </h3>
                      <div className="row nft_grid_layout">
                        {myNFTsClubbed &&
                          myNFTsClubbed.map((nft, index) => (
                            <NFTCards
                              key={index}
                              nft_name={nft.nft_name}
                              collection_id={nft.collection_id}
                              src={nft.image}
                            />
                          ))}
                      </div>
                      <h3>
                        {" "}
                        My Staked NFTs{" "}
                        <span
                          className="cursorClass"
                          onClick={() => authenticate2()}
                        >
                          <i
                            id="fetchStakedNFTicon"
                            className="fa fa-refresh"
                            aria-hidden="true"
                          />
                        </span>
                      </h3>
                      <div className="row nft_grid_layout">
                        {myStakedNFTs &&
                          myStakedNFTs.map((nft, index) => (
                            <StakedNFTCards
                              key={index}
                              nft_name="Moose Society(Staked)"
                              collection_id={nft.collection_id}
                              timer={nft.timeStamp}
                              src={DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC1(
                                nft.tokenID
                              )}
                            />
                          ))}
                      </div>
                      <div className="row justify-ietms-center">
                        <br />
                      </div>
                    </Tab>
                    {/* <Tab eventKey="traxBalance" title=" My Wallet">
                      <div className="row">
                        <div className="col-md-3">
                          <Card
                            style={{ width: "18rem", height: "18rem" }}
                            className="justify-content-center align-items-center"
                          >
                            <Card.Body>
                              <Card.Title>Wallet Balance</Card.Title>

                              <Card.Text>
                                {usersummary.total_trax} TRAX
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-md-3">
                          <Card
                            style={{ width: "18rem", height: "18rem" }}
                            className="justify-content-center align-items-center"
                          >
                            <Card.Body>
                              <Card.Title>Pending Rewards</Card.Title>
                              <Card.Text>
                                {usersummary.pending_reward} TRAX
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-md-3">
                          <Card style={{ width: "18rem", height: "18rem" }}>
                            <Card.Body>
                              <Card.Title>Claim Rewards</Card.Title>
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter Amount</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Enter Amount"
                                  />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                  Claim Now
                                </Button>
                              </Form>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-md-3">
                          <Card style={{ width: "18rem", height: "18rem" }}>
                            <Card.Body>
                              <Card.Title>Daily Trax Earning</Card.Title>
                              <Card.Text>
                                {usersummary.daily_reward} TRAX/day
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>{" "}
                      </div>
                    </Tab> */}
                    <Tab eventKey="unstake" title="Side Quest">
                      <div className="row">
                        <div className="col"></div>
                      </div>

                      <div>
                        <CardGroup>
                          <Card className="side_quest">
                            <Card.Body>
                              <Card.Title className="purple">Send Them Off</Card.Title>
                              <Card.Text>
                                Send a Pair of Moose Off for 90 days and Get Rewarded with a Mini Moose Upon Their Return!
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                              <Card>
                                <Card.Body>
                                  <Card.Title>Select token</Card.Title>
                                  <br />
                                  <Container>
                                    {/* <Row>
                                      <Col sm={6}>NFT Reward</Col>
                                      <Col sm={6}>
                                        <p className="red">
                                          1 MiniMoose
                                          <br />{" "}
                                        </p>
                                      </Col>
                                    </Row> */}

                                    {/* <Row>
                                      <Col sm>TRAX Reward</Col>
                                      <Col sm>
                                        <p className="red">+1800$ TRAX</p>
                                      </Col>
                                    </Row> */}
                                    {/* <Row>
                                      <Col sm={6}>Staking Period</Col>
                                      <Col sm={6}>
                                        <p className="white1">
                                          90 Days
                                          <br />{" "}
                                        </p>
                                      </Col>
                                    </Row> */}
                                    <Row>
                                      <div className="row">
                                        <div className="col-6">
                                          <Figure
                                            className="cursorClass"
                                            onClick={() =>
                                              handleReplaceImage("1", "stack")
                                            }
                                          >
                                            <Figure.Image
                                              width={171}
                                              height={280}
                                              alt="171x180"
                                              className="figImageunstake"
                                              src={
                                                filteredImg.filteredImage1
                                                  .token_id
                                                  ? DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC1(
                                                    filteredImg.filteredImage1
                                                      .token_id
                                                  )
                                                  : "./img/Lex_Son3.png"
                                              }
                                            />
                                            <Figure.Caption className="text-center">
                                              1st NFT
                                            </Figure.Caption>
                                          </Figure>
                                        </div>
                                        <div className="col-6">
                                          <Figure
                                            className="cursorClass"
                                            onClick={() =>
                                              handleReplaceImage("2", "stack")
                                            }
                                          >
                                            <Figure.Image
                                              width={171}
                                              height={180}
                                              className="figImageunstake"
                                              alt="171x180"
                                              // src={
                                              //   filteredImg.filteredImage2
                                              //     .token_id
                                              //     ? DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC1(
                                              //       filteredImg.filteredImage2
                                              //         .token_id
                                              //     )
                                              //     : "./img/Lex_Son2.png"
                                              // }
                                              src={
                                                filteredImg.filteredImage2
                                                  .token_id
                                                  ? DashboardFunctions.getIMAGEURIofNFTfromTokenID_SC1(
                                                    filteredImg.filteredImage2
                                                      .token_id
                                                  )
                                                  : "./img/Lex_Son2.png"
                                              }
                                            />
                                            <Figure.Caption className="text-center">
                                              2nd NFT
                                            </Figure.Caption>
                                          </Figure>
                                        </div>
                                        <>
                                          <Button
                                            className="stakeButtonClass mt-2 mb-1"
                                            variant="primary"
                                            onClick={handleShowStakeButton}
                                          >
                                            Send Off
                                          </Button>
                                          <Modal
                                            show={showStakeButton}
                                            onHide={handleCloseStakeButton}
                                          >
                                            <Modal.Header closeButton>
                                              <Modal.Title>
                                                <img
                                                  src="./favicon.webp"
                                                  alt="img"
                                                  width={20}
                                                  height={10}
                                                  className="img-fluid image lazy nft__item_preview"
                                                />{" "}
                                                &nbsp; Do you wish to continue?
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                              <h6>
                                                This will stake your selected NFTs
                                                for 90 days.
                                              </h6>
                                              <div className="image_arrange"></div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                              <Button
                                                variant="danger"
                                                className="buttonHoverModal cancelBtn"
                                                onClick={handleCloseStakeButton}
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                variant="primary"
                                                className="buttonHoverModal"
                                                onClick={() => stakeNFT(32, 33)}
                                              >
                                                Yes Continue
                                              </Button>
                                            </Modal.Footer>
                                          </Modal>

                                          {/* Below is for Choosing */}
                                          <Modal
                                            show={show}
                                            onHide={handleClose}
                                            className="modal1"
                                          >
                                            <Modal.Header closeButton>
                                              <Modal.Title>
                                                <img
                                                  src="./favicon.webp"
                                                  alt="img"
                                                  width={20}
                                                  height={10}
                                                  className="img-fluid image lazy nft__item_preview"
                                                />{" "}
                                                &nbsp;{" "}
                                                {stacking
                                                  ? "Select NFT you want to stake"
                                                  : "Select NFT you want to unStake"}
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="modal1-body">
                                              <div
                                                className={`row  image_arrange1 ${unStacking && "unstacking_img"
                                                  }`}
                                              >
                                                {stacking &&
                                                  jsonData.map((nft, index) => (
                                                    <img
                                                      onClick={() =>
                                                        selectImage(nft.token_id)
                                                      }
                                                      key={index}
                                                      src={nft.image}
                                                      alt="img"
                                                      className="img-fluid image lazy nft__item_preview"
                                                    />
                                                    // </div>
                                                  ))}
                                                {unStacking &&
                                                  unstackJson.map(
                                                    (nft, index) => {
                                                      return (
                                                        <div
                                                          className="nft_img"
                                                          key={index + 1}
                                                        >
                                                          <img
                                                            onClick={() =>
                                                              selectImageUnstack(
                                                                nft.id
                                                              )
                                                            }
                                                            // src={nft.image1}
                                                            src={`${CONFIG.URI.SC_1_IMAGE_URI}${nft.token_Id1}.png`}
                                                            // src={`https://ipfs.io/ipfs/${mooseipfsdata[nft.id]}`}

                                                            alt="img"
                                                            className="img-fluid image lazy nft__item_preview"
                                                          />
                                                          <img
                                                            onClick={() =>
                                                              selectImageUnstack(
                                                                nft.id
                                                              )
                                                            }
                                                            key={index + 1 + 1}
                                                            // src={nft.image2}
                                                            src={`${CONFIG.URI.SC_1_IMAGE_URI}${nft.token_Id2}.png`}
                                                            // src={`https://ipfs.io/ipfs/${mooseipfsdata[nft.image2]}`}
                                                            alt="img"
                                                            className="img-fluid image lazy nft__item_preview"
                                                          />
                                                          <div className="pair_text">
                                                            <p>
                                                              {index + 1} NFT Of
                                                              The Pair
                                                            </p>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                              {/* <Button
                                              variant="danger"
                                              className="buttonHoverModal"
                                              onClick={handleClose}
                                            >
                                              Close
                                            </Button> */}
                                              <Button
                                                variant="primary"
                                                className="buttonHoverModal"
                                                onClick={handleClose}
                                              >
                                                OK
                                              </Button>
                                            </Modal.Footer>
                                          </Modal>
                                        </>
                                      </div>
                                    </Row>
                                  </Container>
                                </Card.Body>
                              </Card>
                            </Card.Footer>
                          </Card>











                          <Card>
                            <Card.Body>
                              <Card.Title className="purple">Bring Them Back</Card.Title>
                              <Card.Text>
                                Once You Complete Your 90 Days, Your Moose Will Return and Bring Back a Mini Moose & 1800 Moose TRAX Tokens!
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                              <Card>
                                <Card.Body>
                                  <Card.Title>Select pairs</Card.Title>
                                  <br />
                                  <Container>
                                    {/* <Row>
                                      <Col sm={6}>Quantity</Col>
                                      <Col sm={6}>
                                        <p className="white1">
                                          1 MiniMoose
                                          <br />{" "}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm>TRAX Reward</Col>
                                      <Col sm>
                                        <p className="purple">+1800 $TRAX</p>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm={6}>Staking Period</Col>
                                      <Col sm={6}>
                                        <p className="white1">
                                          90 Days
                                          <br />{" "}
                                        </p>
                                      </Col>
                                    </Row> */}
                                    <Row>
                                      <div className="row">
                                        <div className="col-6">
                                          <Figure
                                            className="cursorClass"
                                            onClick={() =>
                                              handleReplaceImage("3", "unstack")
                                            }
                                          >
                                            <Figure.Image
                                              width={171}
                                              height={280}
                                              alt="171x180"
                                              className="figImageunstake"
                                              // src={
                                              //   filteredImgUnStack.filteredImage1
                                              //     .token_Id1
                                              //     ? CONFIG.URI.SC_1_IMAGE_URI +
                                              //     filteredImgUnStack
                                              //       .filteredImage1.token_Id1 +
                                              //     ".png"
                                              //     : "./img/Lex_Son2.png"
                                              // }
                                              src={
                                                filteredImgUnStack.filteredImage1
                                                  .token_Id1
                                                  ? `https://ipfs.io/ipfs/${mooseipfsdata[filteredImgUnStack.filteredImage1.token_Id1]}`
                                                  : "./img/Lex_Son2.png"
                                              }
                                            // src={`https://ipfs.io/ipfs/${mooseipfsdata[filteredImgUnStack.filteredImage1.token_Id1]}` }
                                            />
                                            {/* <Figure.Caption className="text-center">
                                              1st NFT
                                            </Figure.Caption> */}
                                          </Figure>
                                        </div>
                                        <div className="col-6">
                                          <Figure
                                            className="cursorClass"
                                            onClick={() =>
                                              handleReplaceImage("3", "unstack")
                                            }
                                          >
                                            <Figure.Image
                                              width={171}
                                              height={180}
                                              className="figImageunstake"
                                              alt="171x180"
                                              // src={
                                              //   filteredImgUnStack.filteredImage1
                                              //     .token_Id2
                                              //     ? CONFIG.URI.SC_1_IMAGE_URI +
                                              //     filteredImgUnStack
                                              //       .filteredImage1.token_Id2 +
                                              //     ".png"
                                              //     : "./img/Lex_Son2.png"
                                              // }
                                              src={
                                                filteredImgUnStack.filteredImage1
                                                  .token_Id2
                                                  ? `https://ipfs.io/ipfs/${mooseipfsdata[filteredImgUnStack.filteredImage1.token_Id2]}`
                                                  : "./img/Lex_Son2.png"
                                              }

                                            />
                                          </Figure>
                                        </div>
                                        {/* <Figure.Caption className="text-center">
                                          Select Pair
                                        </Figure.Caption> */}
                                        <div className="text-center mb-2 mt-0">
                                          Select Pair
                                        </div>
                                        <>
                                          <Button
                                            className="stakeButtonClass"
                                            variant="primary"
                                            // onClick={handleShowStakeButton}
                                            onClick={handleShowUnStakeButton}
                                          >
                                            Returned
                                          </Button>
                                          <Modal
                                            show={showUnStakeButton}
                                            onHide={handleCloseUnStakeButton}
                                          >
                                            <Modal.Header closeButton>
                                              <Modal.Title>
                                                <img
                                                  src="./favicon.webp"
                                                  alt="img"
                                                  width={20}
                                                  height={10}
                                                  className="img-fluid image lazy nft__item_preview"
                                                />{" "}
                                                &nbsp; Do you wish to continue?
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                              <h6>
                                                This will unstake your selected
                                                NFTs.
                                              </h6>
                                              <div className="image_arrange"></div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                              <Button
                                                variant="danger"
                                                className="buttonHoverModal cancelBtn"
                                                // onHide={handleCloseUnStakeButton}
                                                onClick={handleCloseUnStakeButton}
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                variant="primary"
                                                className="buttonHoverModal"
                                                onClick={() => unstakeNFT(32, 33)}
                                              >
                                                Yes Continue
                                              </Button>
                                            </Modal.Footer>
                                          </Modal>

                                          {/* Below is for Choosing */}
                                          <Modal
                                            show={show}
                                            onHide={handleClose}
                                            className="modal1"
                                          >
                                            <Modal.Header closeButton>
                                              <Modal.Title>
                                                <img
                                                  src="./favicon.webp"
                                                  alt="img"
                                                  width={20}
                                                  height={10}
                                                  className="img-fluid image lazy nft__item_preview"
                                                />{" "}
                                                &nbsp;{" "}
                                                {stacking
                                                  ? "Select NFT you want to stake"
                                                  : "Select NFT you want to unStake"}
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="modal1-body">
                                              <div
                                                className={`row  image_arrange1 ${unStacking && "unstacking_img"
                                                  }`}
                                              >
                                                {stacking &&
                                                  jsonData.map((nft, index) => (
                                                    <img
                                                      onClick={() =>
                                                        selectImage(nft.token_id)
                                                      }
                                                      key={index}
                                                      src={nft.image}
                                                      alt="img"
                                                      className="img-fluid image lazy nft__item_preview"
                                                    />
                                                    // </div>
                                                  ))}
                                                {unStacking &&
                                                  unstackJson.map(
                                                    (nft, index) => {
                                                      return (
                                                        <div
                                                          className="nft_img"
                                                          key={index + 1}
                                                        >
                                                          <img
                                                            onClick={() =>
                                                              selectImageUnstack(
                                                                nft.id
                                                              )
                                                            }
                                                            // src={nft.image1}
                                                            // src={`${CONFIG.URI.SC_1_IMAGE_URI}${nft.token_Id1}.png`}
                                                            src={`https://ipfs.io/ipfs/${mooseipfsdata[nft.token_Id1]}`}

                                                            alt="img"
                                                            className="img-fluid image lazy nft__item_preview"
                                                          />
                                                          <img
                                                            onClick={() =>
                                                              selectImageUnstack(
                                                                nft.id
                                                              )
                                                            }
                                                            key={index + 1 + 1}
                                                            // src={nft.image2}
                                                            // src={`${CONFIG.URI.SC_1_IMAGE_URI}${nft.token_Id2}.png`}
                                                            src={`https://ipfs.io/ipfs/${mooseipfsdata[nft.token_Id2]}`}

                                                            alt="img"
                                                            className="img-fluid image lazy nft__item_preview"
                                                          />
                                                          <div className="pair_text">
                                                            <p>
                                                              {index + 1} NFT Of
                                                              The Pair
                                                            </p>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                              {/* <Button
                                              variant="danger"
                                              className="buttonHoverModal"
                                              onClick={handleClose}
                                            >
                                              Close
                                            </Button> */}
                                              <Button
                                                variant="primary"
                                                className="buttonHoverModal"
                                                onClick={handleClose}
                                              >
                                                OK
                                              </Button>
                                            </Modal.Footer>
                                          </Modal>
                                        </>
                                      </div>
                                    </Row>
                                  </Container>
                                </Card.Body>
                              </Card>
                            </Card.Footer>
                          </Card>

                        </CardGroup>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </section>
          <br />
          <br />
          <FooterFooter />
        </div>
      </div>
    </>
  );
};
export default Index;
