import React from 'react';
import Particle from '../../components/Particle';
import SliderMain from '../../components/SliderCarouselNew';
import FeatureBox from '../../components/FeatureBox';
import Footer from '../../components/footer';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import '../../../assets/custom.scss'
import ColumnNew from '../../components/ColumnNew';
import SliderCarousel from '../../components/SliderCarousel';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from '../../Styles';
//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const TestHome = () => (
    <div className="greyscheme">
        <StyledHeader theme={theme} />
        <section className="jumbotron no-bg relative " style={{ backgroundImage: `url(${'./img/background/8.jpg'})` }}>
            <Particle />
            <div className='container bwhite'>
                <div className='row'>
                    <SliderMain />
                </div>
            </div>
        </section>

        <section className='container no-bottom'>
            <div className="row">
                {
                    [1, 2, 3, 4, 5, 6].map((data, index) => {
                        return (
                            <div key={index} className="col-lg-2 col-sm-4 col-6">
                                <div className="box-url text-center">
                                    <img src="./img/wallet/6.png" alt="" />
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </section>

        <section className='container no-top no-bottom'>
            <div className='row '>
                <div className="spacer-double"></div>
                <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                    <h2>Welcome</h2>
                </Reveal>

                <div className='col-lg-6  mb-2'>
                    <div className='card'>
                        <img src="./img/moose/Lex Mooser.png" alt="Lex"/>
                    </div>
                </div>
                <div className='col-lg-6  mb-2 text-center text-white'>
                    <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
                        <p>
                            <article>The Moose Society NFT's are a limited edition, hand-drawn collection of 5000 males and females. We have spent the last 3 months building the art and creating amazing utility for all our holders!</article>
                            <article>We started the Moose Society with one goal in mind, helping kids.</article>
                        </p>
                    </Reveal>
                    <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
                        <p>
                            <article>By partnering with Excel to Excellence &amp; The Children’s Hospital, we know we can make a difference. With your help, anything is possible! We have the same mindset to help develop innovative programs and strong partnerships that provide the resources, life experiences, and environment to mold individuals into healthy, educated leaders. We also know that some families need that extra help to pay for medical bills. We understand. We care.</article>
                        </p>
                    </Reveal>
                    <Reveal className='onStep' keyframes={fadeInUp} delay={400} duration={600} triggerOnce>
                        <p>
                            <article>
                                That’s why, when &nbsp;NFL Analyst and Super Bowl Champion Michael Robinson found out about our project, we were happy to join forces to make a difference.
                            </article>
                        </p>
                    </Reveal>
                    <Reveal className='onStep' keyframes={fadeInUp} delay={500} duration={600} triggerOnce>
                        <p>
                            <article>
                                Check our Roadmap &amp; WhitePaper and some of the plans we have for the future. I’m truly excited about this journey and glad to have you here with us!
                            </article>
                        </p>
                    </Reveal>
                </div>

            </div>
        </section>

        <section className='container no-top no-bottom'>
            <div className='row'>
                <div className="spacer-double"></div>
                <div className='col-lg-12'>
                    <div className='row'>
                        {
                            [1, 2, 3, 4].map(() => {
                                return (

                                    <div className='col-lg-3'>
                                        <div className='card'>
                                            <img src="./img/moose/Zom Moose.png" alt="Zom Moose" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>

        <section className='container no-top'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="spacer-double"></div>
                        <h2 className='style-2'>Utility</h2>
                    </div>
                </div>
                <FeatureBox />
            </div>
        </section>

        <section className='container no-top'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="spacer-double"></div>
                        <h2 className='style-2'>Teams</h2>
                    </div>
                </div>
                <ColumnNew />
            </div>
        </section>

        <section className="jumbotron no-bg no-bottom">
            <div className='container-fluid'>
                <div className='row'>
                    <SliderCarousel />
                </div>
            </div>
        </section>

        <section className='container no-top'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="spacer-double"></div>
                        <h2 className='style-2'>FAQ</h2>
                    </div>
                </div>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Accordion Item #1
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <strong>This is the first item's accordion body.</strong>
                                It is shown by default, until the collapse
                                plugin adds the appropriate classes that
                                we use to style each element.
                                These classes control the overall appearance,
                                as well as the showing and hiding via CSS transitions.
                                You can modify any of this with custom CSS or
                                overriding our default variables. </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                Accordion Item #2
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body> <strong>This is the first item's accordion body.</strong>
                                It is shown by default, until the collapse
                                plugin adds the appropriate classes that
                                we use to style each element.
                                These classes control the overall appearance,
                                as well as the showing and hiding via CSS transitions.
                                You can modify any of this with custom CSS or
                                overriding our default variables.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                Accordion Item #3
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body> <strong>This is the first item's accordion body.</strong>
                                It is shown by default, until the collapse
                                plugin adds the appropriate classes that
                                we use to style each element.
                                These classes control the overall appearance,
                                as well as the showing and hiding via CSS transitions.
                                You can modify any of this with custom CSS or
                                overriding our default variables.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </section>
        <Footer />

    </div>
);
export default TestHome;