import React, { Component } from "react";
import styled from "styled-components";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

export default class Responsive extends Component {
    dummyData = [
        {
            authorLink: "#",
            previewImg: "./img/moose/Mrs. Chris Moose.png",
            title: "Martin Kelly",
            disc: "Marketing Lead & Shepherd to the herd"
        },
        {
            authorLink: "#",
            previewImg: "./img/moose/Mrs. Chris Moose.png",
            title: "Martin Kelly",
            disc: "Marketing Lead & Shepherd to the herd"
        },
        {
            authorLink: "#",
            previewImg: "./img/moose/Mrs. Chris Moose.png",
            title: "Martin Kelly",
            disc: "Marketing Lead & Shepherd to the herd"
        },
        {
            authorLink: "#",
            previewImg: "./img/moose/Mrs. Chris Moose.png",
            title: "Martin Kelly",
            disc: "Marketing Lead & Shepherd to the herd"
        },
        {
            authorLink: "#",
            previewImg: "./img/moose/Mrs. Chris Moose.png",
            title: "Martin Kelly",
            disc: "Marketing Lead & Shepherd to the herd"
        },
        {
            authorLink: "#",
            previewImg: "./img/moose/Mrs. Chris Moose.png",
            title: "Martin Kelly",
            disc: "Marketing Lead & Shepherd to the herd"
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            nfts: this.dummyData.slice(0, 4),
            height: 0
        };
        this.onImgLoad = this.onImgLoad.bind(this);
    }

    loadMore = () => {
        let nftState = this.state.nfts
        let start = nftState.length
        let end = nftState.length + 4
        this.setState({
            nfts: [...nftState, ...(this.dummyData.slice(start, end))]
        });
    }

    onImgLoad({ target: img }) {
        let currentHeight = this.state.height;
        if (currentHeight < img.offsetHeight) {
            this.setState({
                height: img.offsetHeight
            })
        }
    }

    render() {
        return (
            <div className='row'>
                {this.state.nfts.map((nft, index) => (
                    <div key={index} className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="nft__item m-0">
                            <div className="nft__item_wrap" style={{ height: `${this.state.height}px` }}>
                                <Outer>
                                    <span>
                                        <img onLoad={this.onImgLoad} src={nft.previewImg} className="lazy nft__item_preview" alt="" />
                                    </span>
                                </Outer>
                            </div>
                            <div className="nft__item_info">
                                <span onClick={() => window.open(nft.nftLink, "_self")}>
                                    <h4>{nft.title}</h4>
                                </span>
                                <div className="nft__item_price">
                                    {nft.disc}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}