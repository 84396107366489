import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { createGlobalStyle } from 'styled-components';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

const GlobalStyles = createGlobalStyle`
  .nft-big .slick-prev::before{
    left: 0;
    line-height: 40px;
  }
  .nft-big .slick-next::before {
    right: 0;
    line-height: 40px;
  }
  .nft-big .slick-prev, .nft-big .slick-next{
    border: 1px solid #ccc;
    box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
    width: 50px;
    height: 50px;
  }
`;

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );


  }
}

export default class Responsive extends Component {

  constructor(props) {
    super(props);
    this.state = { deadline: "January, 10, 2022", deadline1: "February, 10, 2022", deadline2: "February, 1, 2022", height: 0 };
  }

  render() {
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: 300,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    };

    return (
      <div className='nft-big p-3'>
        <GlobalStyles />
        <Slider {...settings}>
          <CustomSlide className='itm' index={1}>
            <div className="nft__item_lg">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <img src="./img/moose/Lex Mooser.png" className="img-fluid" alt="" />
                </div>
                <div className="col-md-6">
                  <div className="spacer-single"></div>
                  <h6> <span className="text-uppercase color">Gigaland Market</span></h6>
                  <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
                    <h1 className="col-white">Create, sell or collect digital items.</h1>
                  </Reveal>
                  <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
                    <p className="lead col-white">
                      Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable
                    </p>
                  </Reveal>
                  <div className="spacer-10"></div>
                  <Reveal className='onStep d-inline' keyframes={inline} delay={800} duration={900} triggerOnce>
                    <span onClick={() => window.open("#", "_self")} className="btn-main inline lead">Explore</span>
                    <div className="mb-sm-30"></div>
                  </Reveal>

                  <Reveal className='onStep d-inline' keyframes={inline} delay={900} duration={1200} triggerOnce>
                    <div className="row">
                      <div className="spacer-single"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                          <div className="de_count text-left">
                            <h3><span>94215</span></h3>
                            <h5 className="id-color">Collectibles</h5>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                          <div className="de_count text-left">
                            <h3><span>27</span>k</h3>
                            <h5 className="id-color">Auctions</h5>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                          <div className="de_count text-left">
                            <h3><span>4</span>k</h3>
                            <h5 className="id-color">NFT Artist</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={2}>
            <div className="nft__item_lg">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <img src="./img/moose/Lex Mooser.png" className="img-fluid" alt="" />
                </div>
                <div className="col-md-6">
                  <div className="spacer-single"></div>
                  <h6> <span className="text-uppercase color">Gigaland Market</span></h6>
                  <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
                    <h1 className="col-white">Create, sell or collect digital items.</h1>
                  </Reveal>
                  <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
                    <p className="lead col-white">
                      Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable
                    </p>
                  </Reveal>
                  <div className="spacer-10"></div>
                  <Reveal className='onStep d-inline' keyframes={inline} delay={800} duration={900} triggerOnce>
                    <span onClick={() => window.open("#", "_self")} className="btn-main inline lead">Explore</span>
                    <div className="mb-sm-30"></div>
                  </Reveal>

                  <Reveal className='onStep d-inline' keyframes={inline} delay={900} duration={1200} triggerOnce>
                    <div className="row">
                      <div className="spacer-single"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                          <div className="de_count text-left">
                            <h3><span>94215</span></h3>
                            <h5 className="id-color">Collectibles</h5>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                          <div className="de_count text-left">
                            <h3><span>27</span>k</h3>
                            <h5 className="id-color">Auctions</h5>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                          <div className="de_count text-left">
                            <h3><span>4</span>k</h3>
                            <h5 className="id-color">NFT Artist</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={3}>
            <div className="nft__item_lg">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <img src="./img/moose/Lex Mooser.png" className="img-fluid" alt="" />
                </div>
                <div className="col-md-6">
                  <div className="spacer-single"></div>
                  <h6> <span className="text-uppercase color">Gigaland Market</span></h6>
                  <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
                    <h1 className="col-white">Create, sell or collect digital items.</h1>
                  </Reveal>
                  <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
                    <p className="lead col-white">
                      Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable
                    </p>
                  </Reveal>
                  <div className="spacer-10"></div>
                  <Reveal className='onStep d-inline' keyframes={inline} delay={800} duration={900} triggerOnce>
                    <span onClick={() => window.open("#", "_self")} className="btn-main inline lead">Explore</span>
                    <div className="mb-sm-30"></div>
                  </Reveal>

                  <Reveal className='onStep d-inline' keyframes={inline} delay={900} duration={1200} triggerOnce>
                    <div className="row">
                      <div className="spacer-single"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                          <div className="de_count text-left">
                            <h3><span>94215</span></h3>
                            <h5 className="id-color">Collectibles</h5>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                          <div className="de_count text-left">
                            <h3><span>27</span>k</h3>
                            <h5 className="id-color">Auctions</h5>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                          <div className="de_count text-left">
                            <h3><span>4</span>k</h3>
                            <h5 className="id-color">NFT Artist</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </CustomSlide>

        </Slider>
      </div>
    );
  }
}
