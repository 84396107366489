import { Location, Redirect, Router } from "@reach/router";
import React from "react";
import { createGlobalStyle } from "styled-components";
import auth from "../core/auth";
import Header from "./menu/header";
import ScrollToTopBtn from "./menu/ScrollToTop";
import Accordion from "./pages/accordion";
import Activity from "./pages/activity";
import Activitygrey from "./pages/activityGrey";
import Alerts from "./pages/alerts";
import Auctiongrey from "./pages/AuctionGrey";
import AuthorGrey from "./pages/AuthorGrey";
import Colectiongrey from "./pages/colectionGrey";
import Contact from "./pages/contact";
import Contactgrey from "./pages/contactGrey";
import Create2 from "./pages/create2";
import Create3 from "./pages/create3";
import Creategrey from "./pages/createGrey";
import Createoption from "./pages/createOptions";
import TestHome from "./pages/custom/TestHome";
import Dashboard from "./pages/dashboard/index";
import ElegantIcons from "./pages/elegantIcons";
import EtlineIcons from "./pages/etlineIcons";
import Explore2grey from "./pages/explore2Grey";
import Exploregrey from "./pages/exploreGrey";
import FontAwesomeIcons from "./pages/fontAwesomeIcons";
import Helpcentergrey from "./pages/helpcenterGrey";
import Home2grey from "./pages/home2Grey";
import Home2greyBackup from "./pages/home2GreyBackup";
import ItemDetailReduxgrey from "./pages/ItemDetailReduxGrey";
import Login from "./pages/login";
import Logingrey from "./pages/loginGrey";
import LoginTwo from "./pages/loginTwo";
import LoginTwogrey from "./pages/loginTwoGrey";
import Mintergrey from "./pages/MinterGrey";
import News from "./pages/news";
import NewsSingle from "./pages/newsSingle";
import AuthorOpensea from "./pages/Opensea/author";
import ExploreOpensea from "./pages/Opensea/explore";
import Price from "./pages/price";
import PrivatePage from "./pages/privatepage";
import Profile from "./pages/Profile";
import Progressbar from "./pages/progressbar";
import RankingReduxgrey from "./pages/RankingReduxGrey";
import Register from "./pages/register";
import Registergrey from "./pages/registerGrey";
import Tabs from "./pages/tabs";
import WalletGrey from "./pages/walletGrey";
import Works from "./pages/works";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id="routerhang">
        <div key={location.key}>
          <Router location={location}>{children}</Router>
        </div>
      </div>
    )}
  </Location>
);

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuth = auth.getToken() !== null;

  return isAuth ? (
    <Component {...rest} />
  ) : (
    <Redirect from="" to="/login" noThrow />
  );
};

// async function authenticate2() {
//   let element = document.getElementById("fetchStakedNFTicon");
//   element.classList.add("fa-spin");
//   setTimeout(() => {
//     element.classList.remove("fa-spin");
//   }, 2000);
//   await authenticate();
//   console.log("xyz", xyz)

//   // await Moralis.enableWeb3();
//   setWallet(user.attributes.ethAddress);
//   console.log(user.attributes.ethAddress);
//   console.log(user.get("ethAddress"));
//   if (userError) {
//     console.log("userError", userError);
//   }
// }

const App = () => {
  return (
    <div className="wraper">
      <GlobalStyles />
      <Header />
      <PosedRouter>
        <ScrollTop path="/">
          <Dashboard exact path="/">
            <Redirect to="/home" />
          </Dashboard>
          <Home2grey path="/dashboard" />
          {/* <Home2grey exact path="/">
            <Redirect to="/home" />
          </Home2grey> */}
          {/* <Dashboard path="/dashboard" /> */}
          <TestHome path="/test-home" />
          <Home2greyBackup path="/homebackup" />
          <Exploregrey path="/exploreGrey" />
          <PrivatePage path="/privatepage" />
          <Explore2grey path="/explore2Grey" />
          <ExploreOpensea path="/exploreOpensea" />
          <RankingReduxgrey path="/rangkingGrey" />
          <Auctiongrey path="/AuctionGrey" />
          <Helpcentergrey path="/helpcenterGrey" />
          <Colectiongrey path="/colectionGrey/:collectionId" />
          <ItemDetailReduxgrey path="/ItemDetailGrey/:nftId" />
          {/* 
            PROTECTED ROUTE :
            you can use this to protect your route, user must login first to access
           */}
          <ProtectedRoute component={Profile} path="/Profile/:authorId" />
          {/* 
          <Author path="/Author/:authorId" /> 
          */}
          <Profile path="/profile1" />
          {/* <Dashboard path="/dashboard" /> */}

          <AuthorGrey path="/AuthorGrey/:authorId" />
          <AuthorOpensea path="/AuthorOpensea" />
          <WalletGrey path="/walletGrey" />
          <Login path="/login" />
          <Logingrey path="/loginGrey" />
          <LoginTwo path="/loginTwo" />
          <LoginTwogrey path="/loginTwoGrey" />
          <Register path="/register" />
          <Registergrey path="/registerGrey" />
          <Price path="/price" />
          <Works path="/works" />
          <News path="/news" />
          <NewsSingle path="/news/:postId" />
          <Creategrey path="/createGrey" />
          <Create2 path="/create2" />
          <Create3 path="/create3" />
          <Createoption path="/createOptions" />
          <Activity path="/activity" />
          <Activitygrey path="/activityGrey" />
          <Contact path="/contact" />
          <Contactgrey path="/contactGrey" />
          <ElegantIcons path="/elegantIcons" />
          <EtlineIcons path="/etlineIcons" />
          <FontAwesomeIcons path="/fontAwesomeIcons" />
          <Accordion path="/accordion" />
          <Alerts path="/alerts" />
          <Progressbar path="/progressbar" />
          <Tabs path="/tabs" />
          <Mintergrey path="/minter" />
        </ScrollTop>
      </PosedRouter>
      <ScrollToTopBtn />
    </div>
  );
};
export default App;
