import React from 'react';
import ReactDOM from 'react-dom';
import { MoralisProvider } from "react-moralis";
//redux store
import { Provider } from 'react-redux';
// react toastify css
import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import "./assets/animated.css";
import './assets/style.scss';
import './assets/style_grey.scss';
import App from './components/app';
import { CONFIG } from './config';
import * as serviceWorker from './serviceWorker';
import store from './store';



// Ropsten Details
// <MoralisProvider appId="tExjBBJ1s8aHSk1idnOJJOrjJON007ziPVLk88fn" serverUrl="https://xltiotldo06b.usemoralis.com:2053/server">
// Eth Mainnet Details
// <MoralisProvider appId="eBgX8zrn8xVXiY0baycBC2ICW9MCah153bbZflyr" serverUrl="https://o5frzwdhepdu.usemoralis.com:2053/server">
// Rinkyby Details
//<MoralisProvider appId="SijSgTYh4BzkYWphDDTxtpaASUW2n8ALsss5GM2S" serverUrl="https://2wrtzriiflxd.usemoralis.com:2053/server">


ReactDOM.render(
	// <MoralisProvider appId="SijSgTYh4BzkYWphDDTxtpaASUW2n8ALsss5GM2S" serverUrl="https://2wrtzriiflxd.usemoralis.com:2053/server">
	<MoralisProvider appId={CONFIG.moralis_app_ID} serverUrl={CONFIG.moralis_Server_URL}>
		<Provider store={store}>
			<App />
		</Provider>
	</MoralisProvider>,
	document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();