import axios from "axios";
import { CONFIG } from "../../../config.js";
import mooseipfsdata from "./mooseipfs.json";



function getPairsOfNFT(myFilteredArray) {
    let getAllHashes = myFilteredArray.map(currValue => currValue.hash)
    const getAllUniqueHashesSet = new Set(getAllHashes);
    const getAllUniqueHashes = Array.from(getAllUniqueHashesSet);
    // console.log("getAllHashes=>", getAllHashes)
    // console.log("getAllUniqueHashesSet=>", getAllUniqueHashesSet)
    // console.log("ArrayfromSet=>", Array.from(getAllUniqueHashes))
    let newArray = []
    for (let i = 0; i < getAllUniqueHashes.length; i++) {
        let tempArrayForComparing = myFilteredArray.filter(function (e) {
            if (e.hash == getAllUniqueHashes[i]) {
                return true;
            } else {
                return false;
            }
        });
        newArray[i] = {
            id: i,
            index: i,
            hash: getAllUniqueHashes[i],
            token_Id1: tempArrayForComparing[0].tokenID,
            token_Id2: tempArrayForComparing[1].tokenID,
            image1: getIMAGEURIofNFTfromTokenID_SC1(tempArrayForComparing[0].tokenID),
            image2: getIMAGEURIofNFTfromTokenID_SC1(tempArrayForComparing[1].tokenID),
            owner: tempArrayForComparing[0].from
        }
    }
    // console.log("FinalnewArray=>", newArray)
    return newArray
}


async function getImageFromBrianServer(tokenID) {
    console.log("mooseipfsdata", mooseipfsdata)
    try {
        let arrayTemp = []
        var config = {
            headers: { 'Access-Control-Allow-Origin': '*' }
        };
        return mooseipfsdata[5]
        for (let i = 0; i < 10; i++) {
            const response = await axios.get(
                `https://www.moosesocietynft.com/api/${i}`
            );
            arrayTemp[i] = response.data;
        }
        // let tempArray = response.data.result;
        return arrayTemp
    } catch (error) {
        return 0
    }

}
function getIMAGEURIofNFTfromTokenID_SC1(tokenID) {

    console.log("TEST NOW PLEASEtokenID", tokenID, CONFIG.chainID)
    if (CONFIG.chainID === "eth") {
        let url = `https://ipfs.io/ipfs/${mooseipfsdata[tokenID]}`

        // CONFIG.SC_1_IMAGE_URI = url;
        console.log("TEST NOW PLEASEtokenID==", tokenID)
        return url
    }
    else if (CONFIG.chainID === "rinkeby") {
        // let url = `${CONFIG.URI.SC_1_IMAGE_URI}${tokenID}.png`
        let url = `https://ipfs.io/ipfs/${mooseipfsdata[tokenID]}`
        console.log("TEST NOW PLEASEtokenID==", tokenID)
        return url
    }
}
// async function getIMAGEURIofNFTfromTokenID_SC1_test(tokenID) {
async function getIMAGEURIofNFTfromTokenID_SC4(tokenID) {

    // try {
    const response = await axios.get(
        `https://minimoose.moosesocietynft.io/sidequest/api/${tokenID}`
    );

    // console.log("222",response.data.result);
    console.log("222", response.data);
    return (response.data.image);

    // let tempArray = response.data.result;
    // console.log(tempArray);

    // console.log("TEST NOW PLEASEtokenID", tokenID, CONFIG.chainID)
    // if (CONFIG.chainID === "eth") {
    //     let url = `https://ipfs.io/ipfs/${mooseipfsdata[tokenID]}`
    //     console.log("TEST NOW PLEASEtokenID==", tokenID)
    //     return url
    // }
    // else if (CONFIG.chainID === "rinkeby") {
    //     let url = `${CONFIG.URI.SC_1_IMAGE_URI}${tokenID}.png`
    //     console.log("TEST NOW PLEASEtokenID==", tokenID)
    //     return url
    // }
}
async function getIMAGEURIofNFTfromTokenID_SC3_test(tokenID) {

    // try {
    const response = await axios.get(
        `https://minimoose.moosesocietynft.io/minimoose/api/${tokenID}`
    );

    // console.log("222",response.data.result);
    console.log("222", response.data);
    return (response.data.image);

    // let tempArray = response.data.result;
    // console.log(tempArray);

    // console.log("TEST NOW PLEASEtokenID", tokenID, CONFIG.chainID)
    // if (CONFIG.chainID === "eth") {
    //     let url = `https://ipfs.io/ipfs/${mooseipfsdata[tokenID]}`
    //     console.log("TEST NOW PLEASEtokenID==", tokenID)
    //     return url
    // }
    // else if (CONFIG.chainID === "rinkeby") {
    //     let url = `${CONFIG.URI.SC_1_IMAGE_URI}${tokenID}.png`
    //     console.log("TEST NOW PLEASEtokenID==", tokenID)
    //     return url
    // }
}
function getJSONURIofNFTfromTokenID_SC1(tokenID) {
    let url = `${CONFIG.URI.SC_1_JSON_URI}${tokenID}.json`
    return url
}
function getIMAGEURIofNFTfromTokenID_SC3(tokenID) {
    let url = `${CONFIG.URI.SC_3_IMAGE_URI}${tokenID}.png`
    return url
}
function getJSONURIofNFTfromTokenID_SC3(tokenID) {
    let url = `${CONFIG.URI.SC_3_JSON_URI}${tokenID}.json`
    return url
}
function getJSONURIofNFTfromTokenID_SC4(tokenID) {
    let url = `${CONFIG.URI.SC_4_JSON_URI}${tokenID}`
    return url
}

async function fetchDataEtherScan() {
    try {
        const response = await axios.get(
            "https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0xF63063bB20a03B85Bd08d5C1244AF8bA0aEE1B1F&page=1&offset=5000&sort=asc&apikey=BTPMZFG4Q5JD2WK6VTGRJVS5CTIBMV9XB4"
        );
        let tempArray = response.data.result;
        // console.log(response.data);
        // console.log(response.data.result);
        // console.log(tempArray);
        let myFilteredArrayFromEtherScan = tempArray.filter(function (e) {
            if (e.to == "0xf63063bb20a03b85bd08d5c1244af8ba0aee1b1f") {
                return true;
            } else {
                return false;
            }
        });
        console.log(myFilteredArrayFromEtherScan);
        return {
            status: true,
            message: "Data fetched Successfully",
            data: myFilteredArrayFromEtherScan,
            error: null
        }
    } catch (error) {
        return {
            status: false,
            message: null,
            data: null,
            error: error
        }
    }
}

// moralis_app_ID: moralis_app_ID,
// moralis_Server_URL: moralis_Server_URL,
// moralis_chain: chainID,
// smart_contract_erc721: moralis_contract_erc721,
// smart_contract_erc20: moralis_contract_erc20,
const DashboardFunctions = {

    getPairsOfNFT: getPairsOfNFT,
    fetchDataEtherScan: fetchDataEtherScan,
    getIMAGEURIofNFTfromTokenID_SC1: getIMAGEURIofNFTfromTokenID_SC1,
    getJSONURIofNFTfromTokenID_SC1: getJSONURIofNFTfromTokenID_SC1,
    getIMAGEURIofNFTfromTokenID_SC3: getIMAGEURIofNFTfromTokenID_SC3,
    getJSONURIofNFTfromTokenID_SC3: getJSONURIofNFTfromTokenID_SC3,
    getImageFromBrianServer: getImageFromBrianServer,


    getIMAGEURIofNFTfromTokenID_SC4: getIMAGEURIofNFTfromTokenID_SC4,
    getJSONURIofNFTfromTokenID_SC4: getJSONURIofNFTfromTokenID_SC4,
    getIMAGEURIofNFTfromTokenID_SC3_test: getIMAGEURIofNFTfromTokenID_SC3_test,
}
const ABI = {
    mintMetaMooseMansion: [
        {
            inputs: [{ internalType: "uint256", name: "_count", type: "uint256" }],
            name: "mintMetaMooseMansion",
            outputs: [],
            stateMutability: "payable",
            type: "function",
        },
    ],
    stakeMoose: [
        {
            inputs: [
                { internalType: "uint256", name: "_tokenId1", type: "uint256" },
                { internalType: "uint256", name: "_tokenId2", type: "uint256" },
            ],
            name: "stakeMoose",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
    ],
    // award3: [
    //     { inputs: [{ "internalType": "string", "name": "tokenURI1", "type": "string" }, { "internalType": "string", "name": "tokenURI2", "type": "string" }, { "internalType": "address", "name": "to", "type": "address" }], "name": "award3", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
    // ],
    // award3: [
    //     { inputs: [{ "internalType": "string", "name": "tokenURI1", "type": "string" }, { "internalType": "string", "name": "tokenURI2", "type": "string" }], "name": "award3", "outputs": [], "stateMutability": "nonpayable", "type": "function" }
    // ],
    award3: [
        {
            "inputs": [
                {
                    "internalType": "string",
                    "name": "tokenURI1",
                    "type": "string"
                },
                {
                    "internalType": "string",
                    "name": "tokenURI2",
                    "type": "string"
                }
            ],
            "name": "award3",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ],
    totalSupply: [
        {
            inputs: [],
            name: "totalSupply",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
        },
    ],
    getTotalClaimable: [
        {
            inputs: [{ "internalType": "address", "name": "user", "type": "address" }], name: "getTotalClaimable",
            outputs: [{ "internalType": "uint256", "name": "", "type": "uint256" }], stateMutability: "view",
            type: "function"
        },
    ],


}


export { DashboardFunctions, ABI }