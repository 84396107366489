import React from "react";

const NFTCards = (props) => (
    <div className="d-item">
        <div className="nft__item m-0">
            <div className="nft__item_wrap m-0">
                <div className="sc-gsDKAQ jnjfOW m-0">
                    <span>
                        <img src={props.src} className="lazy nft__item_preview" alt="" />
                    </span>
                </div>
            </div>
            <div className="nft__item_info mt-0 pt-0">
                <span>
                    <h4>{props.nft_name}</h4>
                </span>
                <div className="row nft__item_action mb-3">
                    <div className="col-7">     <span>Collection #{props.collection_id}</span></div>
                    <div className="col-5 text-right"><div className="nft__item_price text-right">0.01 ETH</div></div>


                </div>
                <div className="nft__item_like">
                </div>
            </div>
        </div>
    </div>



);
export default NFTCards;
