import React, { memo, useEffect } from "react";
import {useDispatch } from 'react-redux';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
// import * as selectors from '../../store/selectors';
import { getBlogPosts } from "../../store/actions/thunks";
import { Link } from '@reach/router';



const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const PrivatePage = () => {
  // const navigateTo = (link) => {
  //   navigate(link);
  // }

  const dispatch = useDispatch();
  // const blogsState = useSelector(selectors.blogsState);
  // const blogPosts = blogsState.data ? blogsState.data : [];

  useEffect(() => {
    dispatch(getBlogPosts());
  }, [dispatch]);

  return (
    <div>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})` }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12 text-center'>
                <h1>Private Page</h1>
                <p>This is a Private Page for Developers</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container'>
        <div className="row">
          <div className="col-4">
            <NavLink to="/home2backup" >Home Backup</NavLink><br />
            <NavLink to="/exploreGrey" >Explore Grey</NavLink><br />
            <NavLink to="/explore2Grey" >Explore  2 Grey</NavLink><br />
            <NavLink to="/exploreOpensea" >Explore OpenSea</NavLink><br />
            <NavLink to="/rangkingGrey" >Rangking Grey</NavLink><br />
            <NavLink to="/colectionGrey/1" >Collection Grey</NavLink><br />
            <NavLink to="/ItemDetailGrey/1" >Items Details Grey</NavLink><br />
            <NavLink to="/AuctionGrey" >Live Auction Grey</NavLink><br />
            <NavLink to="/helpcenterGrey" >Help Center Grey</NavLink><br />
          </div>
          <div className="col-4">
            <NavLink to="/Profile/1" >Profile</NavLink><br />
            <NavLink to="/AuthorGrey/1" >Author Grey</NavLink><br />
            <NavLink to="/AuthorOpensea" >Author OpenSea</NavLink><br />
            <NavLink to="/walletGrey" >Wallet Grey</NavLink><br />
            <NavLink to="/create2" >Create 2</NavLink><br />
            <NavLink to="/createOptions" >Create options</NavLink><br />
            <NavLink to="/minter" >Nft Minting Grey</NavLink><br />
            <NavLink to="/news" >News</NavLink><br />
            <NavLink to="/works" >Gallery</NavLink><br />
            <NavLink to="/login" >login</NavLink><br />
            <NavLink to="/loginTwo" >login 2</NavLink><br />
            <NavLink to="/register" >Register</NavLink><br />
            <NavLink to="/contact" >Contact Us</NavLink><br />
          </div>
          <div className="col-4">
            <NavLink to="/elegantIcons" >Elegant Icon</NavLink><br />
            <NavLink to="/etlineIcons" >Etline Icon</NavLink><br />
            <NavLink to="/fontAwesomeIcons" >Font Awesome Icon</NavLink><br />
            <NavLink to="/accordion" >Accordion</NavLink><br />
            <NavLink to="/alerts" >Alerts</NavLink><br />
            <NavLink to="/price" >Pricing Table</NavLink><br />
            <NavLink to="/progressbar" >Progress bar</NavLink><br />
            <NavLink to="/tabs" >Tabs</NavLink><br />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
};

export default memo(PrivatePage);