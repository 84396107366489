import React, { useState } from "react";

const StakedNFTCards = (props) => {
  // Set the date we're counting down to
  // let countDownDate = new Date(props.timer).getTime();
  let calculate90Days = (parseInt(props.timer) + 90 * 24 * 60 * 60) * 1000
  let countDownDate = new Date(calculate90Days).getTime();

  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const getTimer = setInterval(() => {
    const now = new Date().getTime();
    const distance = countDownDate - now;
    setTimer({
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000),
    });
    if (distance < 0) {
      clearInterval(getTimer);
    }
  }, 1000);
  // col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4
  return (
    <div className="d-item">
      <div className="nft__item my_stacked m-0">
        <div className="icontype">
          <i className="fa fa-bookmark"></i>
        </div>
        <div className="de_countdown">
          <div>
            <div className="Clock-days">{timer.days && timer.days}d</div>
            <div className="Clock-hours">{timer.hours && timer.hours}h</div>
            <div className="Clock-minutes">
              {timer.minutes && timer.minutes}m
            </div>
            <div className="Clock-seconds">
              {timer.seconds && timer.seconds}s
            </div>
          </div>
        </div>
        <div className="author_list_pp"></div>
        <div className="nft__item_wrap">
          <div className="sc-gsDKAQ jnjfOW">
            <span>
              <img src={props.src} className="lazy nft__item_preview" alt="" />
            </span>
          </div>
        </div>
        <div className="nft__item_info mt-0 pt-0">
          <span>
            <h4>{props.nft_name}</h4>
          </span>
          <div className="row nft__item_action mb-3">
            <div className="col-7">
              {" "}
              <span>Collection #{props.collection_id}</span>
            </div>
            <div className="col-5 text-right">
              <div className="nft__item_price text-right">0.01 ETH</div>
            </div>
          </div>
          <div className="nft__item_like"></div>
        </div>
      </div>
    </div>
  );
};

export default StakedNFTCards;