import React from 'react';
import Particle from '../components/Particle';
import SliderMainParticleGrey from '../components/SliderMainParticleGrey';
import FooterFooter from '../components/footerfooter';

//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from '../Styles';
//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const homeone = () => (
  <div className="greyscheme">
    <StyledHeader theme={theme} />
    <section className="jumbotron no-bg relative" style={{ backgroundImage: `url(${'./img/background/8.jpg'})` }}>
      <Particle />
      <SliderMainParticleGrey />
    </section>
    <div>
    </div>
    <section id="story" className='container no-bottom'>
      <div className="row text-center">
        <h1>Story Section</h1>
      </div>
    </section>
    <section id="utility" className='container no-bottom'>
      <div className="row text-center">
        <h1>Utility Section</h1>
      </div>
    </section>
    <section id="roadmap" className='container no-bottom'>
      <div className="row text-center">
        <h1>ROADMAP Section</h1>
      </div>
    </section>
    <section id="team" className='container no-bottom'>
      <div className="row text-center">
        <h1>Team Section</h1>

      </div>
    </section>
    <section id="contact" className='container no-bottom'>
      <div className="row text-center">
        <h1>FAQ and Contact Section</h1>
      </div>
      <br />
    </section>
    <FooterFooter />

  </div>
);
export default homeone;